

.list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  }
  
  .tags {

    display: grid;
    grid-template-columns: 1fr 1fr; 

    box-shadow: transparent 0 0 0 2px,rgba(18, 18, 18, .2) 0 3px 8px;
    border: 1px solid rgba(18, 18, 18, .3);
    padding: .5rem;
    margin: .2rem;

    width: 100%;
    margin-left: 38%;
    margin-right: 10%;
  }

  .text-row {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .tagtitle {
    margin-right: 37%;
  }
  
  .sort {
    margin-top: 2%;
    margin-left: 40%;
  }

  .results {
    margin-top: 1%;
    margin-left: 40%;
  }

  
  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .2) 0 6px 10px;
    padding: .8rem;
    margin-bottom: 1rem;
  }
  
  
  .button-35 {
    align-items: center;
    background-color: #fff;
    box-shadow: transparent 0 0 0 2px,rgba(18, 18, 18, .2) 0 3px 6px;
    color: #121212;
    cursor: pointer;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    transition: box-shadow .2s,-webkit-box-shadow .2s;
    white-space: nowrap;
    border: 0;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: 0.7rem;
  }
  
  .button-35:hover {
    box-shadow: #121212 0 0 0 2px, transparent 0 0 0 0;
  }
  
  h4 {
    margin-top: .5rem;
  }
  
  .sortbutt {
      align-items: center;
      background-color: #fff;
      box-shadow: transparent 0 0 0 2px,rgba(18, 18, 18, .1) 0 6px 10px;
      box-sizing: border-box;
      color: #121212;
      cursor: pointer;
      display: inline-flex;
      flex: 1 1 auto;
      font-size: 1.2rem;
      font-weight: 600;
      justify-content: center;
      padding: .4rem .4rem;
      text-align: center;
      text-decoration: none;
      transition: box-shadow .2s,-webkit-box-shadow .2s;
      white-space: nowrap;
      border: 0;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      max-width: 300px;
      border: 1px solid rgba(18, 18, 18, .25);
    }
  
  
    .tagbutt:focus {
      box-shadow: #121212 0 0 0 2px, transparent 0 0 0 0;
    }
  
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr; 
      width: 80%
    }
  
    .gains {
      background-color: #39e060;
    }
  
    
  
    .footer {
      display: flex;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      padding: 1rem;
      margin: 1.2rem;
      border-radius: .5rem;
    }


    .sortbuttitem {
      align-items: center;
      background-color: #fff;
      color: #121212;
      cursor: pointer;
      font-weight: 600;
      justify-content: center;
      text-decoration: none;
      transition: box-shadow .2s,-webkit-box-shadow .2s;
      touch-action: manipulation;
      box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .2) 0 6px 20px;
      box-sizing: border-box;
      border: 1px solid rgba(18, 18, 18, .2);
    }